import IMOG from '~/lib/imog';
import Paper from 'paper';
import _ from 'underscore';
import { map } from '~/lib/math';

const arrowPath = `M13 2 13 9 17 9 10 18 3 9 7 9 7 2z`;

const urlParams = new URLSearchParams(window.location.search);

export default IMOG.Component('Guides', {
  options: {
    paper: null,
    addTo: null,
    mainColor: 'blue',
    smallColor: '#ccc',
  },

  props() {
    return {
      size: { width: 500, height: 500 },
      strokeWidth: 1,

      mainVisible: urlParams.get('mainVisible')
        ? urlParams.get('mainVisible') !== 'false'
        : false,
      smallVisible: urlParams.get('smallVisible')
        ? urlParams.get('smallVisible') !== 'false'
        : true,
      variablesVisible: urlParams.get('variablesVisible')
        ? urlParams.get('variablesVisible') !== 'false'
        : true,

      logoData: { v1: 5, v2: 10, v3: 15, v4: 20, h: 10, c: [], o: [] },
      computedData: (props) => ({ ...props.logoData, ...props.size }),
    };
  },

  setup({ options }) {
    this.paper = options.paper;

    this.mainLayer = new this.paper.Layer();
    options.addTo.addChild(this.mainLayer);

    this.variablesLayer = new this.paper.Layer();
    options.addTo.addChild(this.variablesLayer);

    this.mainVertical = _.range(6).map((i) => {
      const p = new this.paper.Path();
      p.strokeColor = options.mainColor;
      p.add(new Paper.Point(0, 0));
      p.add(new Paper.Point(100, 0));
      this.mainLayer.addChild(p);
      return p;
    });

    this.mainHorizontal = _.range(3).map((i) => {
      const p = new this.paper.Path();
      p.strokeColor = options.mainColor;
      p.add(new Paper.Point(0, 0));
      p.add(new Paper.Point(100, 0));
      this.mainLayer.addChild(p);
      return p;
    });

    this.vericals = _.range(4).map((i) => {
      const l = new this.paper.Layer();
      this.variablesLayer.addChild(l);
      const arrow = new this.paper.Path(arrowPath);
      arrow.position.x = 0;
      arrow.position.y = -arrow.bounds.height;
      arrow.fillColor = options.mainColor;
      l.addChild(arrow);

      const text = new this.paper.PointText({
        position: [0, -35],
        justification: 'center',
        content: `V${i + 1}`,
        fillColor: options.mainColor,
        fontSize: 15,
      });
      l.addChild(text);

      l.textRef = text;

      // const c = new this.paper.Path.Circle([0, 0], 2);
      // c.fillColor = 'blue';
      // l.addChild(c);

      return l;
    });

    this.horizontals = _.range(1).map((i) => {
      const l = new this.paper.Layer();
      this.variablesLayer.addChild(l);
      const arrow = new this.paper.Path(arrowPath);
      arrow.rotate(-90);
      arrow.position.x = -arrow.bounds.width;
      arrow.position.y = 0;
      arrow.fillColor = options.mainColor;
      l.addChild(arrow);

      const text = new this.paper.PointText({
        position: [-30, 5],
        justification: 'right',
        content: `H`,
        fillColor: options.mainColor,
        fontSize: 15,
      });
      l.addChild(text);

      l.textRef = text;

      // const c = new this.paper.Path.Circle([0, 0], 2);
      // c.fillColor = 'blue';
      // l.addChild(c);

      return l;
    });

    let linesRaster;
    (() => {
      const rg = new this.paper.Group();
      options.addTo.addChild(rg);

      const mask = new this.paper.Path.Rectangle(0, 0, 250 * 8, 250);
      rg.addChild(mask);

      const lines = new this.paper.Layer();
      rg.addChild(lines);

      _.times(800, (i) => {
        const p = new this.paper.Path();
        p.strokeColor = 'green';
        p.strokeWidth = 1;
        p.add(new Paper.Point(-250 + 250 + i * 5, 0));
        p.add(new Paper.Point(-250 + i * 5, 250));
        lines.addChild(p);
      });

      rg.clipped = true;
      linesRaster = rg.rasterize({
        resolution: this.paper.view.resolution * 2,
      });
      rg.visible = false;
    })();

    this.cuts = _.range(5).map((i) => {
      const c = new this.paper.Group();
      const lr = linesRaster.clone();
      c.addChild(lr);
      const m = new this.paper.Path.Rectangle([0, 0], [20, 20]);
      c.addChild(m);
      m.clipMask = true;
      c.visible = false;
      this.variablesLayer.addChild(c);
      c.maskRef = m;
      return c;
    });
    linesRaster.visible = false;
  },

  hooks: {
    'set:mainVisible'(v) {
      this.mainLayer.visible = v;
    },
    'set:variablesVisible'(v) {
      this.variablesLayer.visible = v;
    },
    'set:strokeWidth'(w) {
      [...this.mainVertical, ...this.mainHorizontal].forEach((item, i) => {
        item.strokeWidth = w;
      });
    },
    'set:computedData'({ width, height, v1, v2, v3, v4, h, c, o }) {
      v1 = (v1 / 20) * width;
      v2 = (v2 / 20) * width;
      v3 = (v3 / 20) * width;
      v4 = (v4 / 20) * width;
      const vs = [0, v1, v2, v3, v4, width];

      h = (h / 20) * height;
      const hs = [0, h, height];

      if (_.isNaN(v1)) return;

      this.vericals[0].position.x = v1;
      this.vericals[0].textRef.content = `${Math.round(
        this.props.computedData.v1
      )
        .toString(21)
        .toUpperCase()}`;
      this.vericals[1].position.x = v2;
      this.vericals[1].textRef.content = `${Math.round(
        this.props.computedData.v2
      )
        .toString(21)
        .toUpperCase()}`;
      this.vericals[2].position.x = v3;
      this.vericals[2].textRef.content = `${Math.round(
        this.props.computedData.v3
      )
        .toString(21)
        .toUpperCase()}`;
      this.vericals[3].position.x = v4;
      this.vericals[3].textRef.content = `${Math.round(
        this.props.computedData.v4
      )
        .toString(21)
        .toUpperCase()}`;
      this.horizontals[0].position.y = h;
      this.horizontals[0].textRef.content = `${Math.round(
        this.props.computedData.h
      )
        .toString(21)
        .toUpperCase()}`;

      this.mainVertical.forEach((item, i) => {
        item.segments[0].point.x = vs[i];
        item.segments[1].point.x = vs[i];
        item.segments[0].point.y = 0;
        item.segments[1].point.y = height;
      });
      this.mainHorizontal.forEach((item, i) => {
        item.segments[0].point.x = 0;
        item.segments[1].point.x = width;
        item.segments[0].point.y = hs[i];
        item.segments[1].point.y = hs[i];
      });

      this.cuts.forEach((cc, i) => {
        const cd = c[i];
        if (!cd) {
          cc.visible = false;
        } else {
          cc.visible = true;
          this.setRectangeBlock(cc.maskRef, cd.block);
        }
      });
    },
  },

  methods: {
    setRectangeBlock(path, index) {
      let { width, height, v1, v2, v3, v4, h, c, o } = this.props.computedData;

      const v0 = 0;
      v1 = (v1 / 20) * width;
      v2 = (v2 / 20) * width;
      v3 = (v3 / 20) * width;
      v4 = (v4 / 20) * width;
      const v5 = width;

      h = (h / 20) * height;
      const hs = [0, h, height];

      const cs = _.range(5).map((i) => {
        const match = c.find((cc) => {
          if (!cc) return 0;
          return cc.block === i + 1;
        });
        if (!match) return 0;
        const bh =
          i < 3 ? 20 - this.props.computedData.h : this.props.computedData.h;
        return ((bh - match.amount) / 20) * height;
      });

      const osr = _.range(5).map((i) => {
        const match = o.find((cc) => {
          if (!cc) return 0;
          return +cc.block === i + 1 && cc.side === 'R';
        });
        if (!match) return 0;
        return (match.amount / 20) * width;
      });

      const osl = _.range(5).map((i) => {
        const match = o.find((cc) => {
          if (!cc) return 0;
          return +cc.block === i + 1 && cc.side === 'L';
        });
        if (!match) return 0;
        return (match.amount / 20) * width;
      });

      switch (index) {
        case 1:
          path.segments[0].point.x = v0;
          path.segments[0].point.y = h + cs[0];
          path.segments[1].point.x = v1 + osr[0];
          path.segments[1].point.y = h + cs[0];
          path.segments[2].point.x = v1 + osr[0];
          path.segments[2].point.y = height;
          path.segments[3].point.x = v0;
          path.segments[3].point.y = height;
          break;

        case 2:
          path.segments[0].point.x = v2 - osl[1];
          path.segments[0].point.y = h + cs[1];
          path.segments[1].point.x = v3 + osr[1];
          path.segments[1].point.y = h + cs[1];
          path.segments[2].point.x = v3 + osr[1];
          path.segments[2].point.y = height;
          path.segments[3].point.x = v2 - osl[1];
          path.segments[3].point.y = height;
          break;

        case 3:
          path.segments[0].point.x = v4 - osl[2];
          path.segments[0].point.y = h + cs[2];
          path.segments[1].point.x = v5;
          path.segments[1].point.y = h + cs[2];
          path.segments[2].point.x = v5;
          path.segments[2].point.y = height;
          path.segments[3].point.x = v4 - osl[2];
          path.segments[3].point.y = height;
          break;

        case 4:
          path.segments[0].point.x = v1 - osl[3];
          path.segments[0].point.y = 0;
          path.segments[1].point.x = v2 + osr[3];
          path.segments[1].point.y = 0;
          path.segments[2].point.x = v2 + osr[3];
          path.segments[2].point.y = h - cs[3];
          path.segments[3].point.x = v1 - osl[3];
          path.segments[3].point.y = h - cs[3];
          break;

        case 5:
          path.segments[0].point.x = v3 - osl[4];
          path.segments[0].point.y = 0;
          path.segments[1].point.x = v4 + osr[4];
          path.segments[1].point.y = 0;
          path.segments[2].point.x = v4 + osr[4];
          path.segments[2].point.y = h - cs[4];
          path.segments[3].point.x = v3 - osl[4];
          path.segments[3].point.y = h - cs[4];
          break;
      }
    },
  },
});
