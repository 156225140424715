import _ from 'underscore';

const block_remap = { 1: 1, 2: 3, 3: 5, 4: 2, 5: 4 };

export default (props) => {
  let TOP = `${Math.round(props.h)}-`;
  let BOTTOM = `${20 - Math.round(props.h)}-`;

  const v = 'v1 v2 v3 v4'.split(' ').map((v) => Math.round(props[v]));
  v.unshift(0);
  v.push(20);

  const blocks = _.range(1, 6).map((i) => {
    const width = v[i] - v[i - 1];
    const cut = props.c.find((cc) => cc && block_remap[cc.block] === i);
    const overlap = props.o.find((oo) => oo && block_remap[oo.block] === i);

    return `${width}${
      (cut && Math.round(cut.amount) > 0) ||
      (overlap && Math.round(overlap.amount) > 0)
        ? '.' + Math.round(cut ? cut.amount : 0)
        : ''
    }${
      overlap && Math.round(overlap.amount) > 0
        ? '.' + overlap.side + Math.round(overlap.amount)
        : ''
    }`;
  });

  TOP += `${blocks[1]},${blocks[3]}`;
  BOTTOM += `${blocks[0]},${blocks[2]},${blocks[4]}`;

  return `M ${TOP} ${BOTTOM}`;
};
