import IMOG from '~/lib/imog';
import Paper from 'paper';
import _ from 'underscore';
import { gsap } from 'gsap';

import Guides from './guides';
import Blocks from './blocks';
import * as RandomGenerator from './randomGenerator';
import codeGenerator from './codeGenerator';

export default IMOG.Component('Logo', {
  options: {
    paper: null,
    addTo: null,
  },

  props() {
    return {
      size: {
        width: 100,
        height: 100,
      },

      visible: true,

      h: 8,
      v1: 4,
      v2: 8,
      v3: 12,
      v4: 16,
      // c: [{ block: 2, amount: 2 }],
      c: [],
      // o: [{ block: 1, amount: 2, side: 'R' }],
      o: [],

      adjust: 0.5,

      logoData: (props) => ({
        v1: props.v1,
        v2: props.v2,
        v3: props.v3,
        v4: props.v4,
        h: props.h,
        c: props.c,
        o: props.o,
        adjust: props.adjust,
      }),

      code: codeGenerator,
    };
  },

  setup({ options }) {
    this.paper = options.paper;

    this.layer = new this.paper.Layer();
    options.addTo.addChild(this.layer);

    this.guides = new Guides({
      options: {
        paper: this.paper,
        addTo: this.layer,
      },
      props: {
        size: (props) => this.props.size,
        // strokeWidth: (props) => 1 / this.props.zoom,
        strokeWidth: 1,
        logoData: (props) => {
          return this.props.logoData;
        },
      },
    });

    this.blocks = new Blocks({
      options: {
        paper: this.paper,
        addTo: this.layer,
      },
      props: {
        size: (props) => this.props.size,
        visible: (props) => this.props.visible,
        logoData: (props) => {
          return this.props.logoData;
        },
      },
    });
  },

  methods: {
    loadFromCode(code, apply = true) {
      let rows = code.split(' ');
      rows.shift();
      rows = rows.map((row) => {
        const split = row.split('-');
        const datas = split[1]
          .split(',')
          .map((v) => v.split('.').map((v) => v));
        return {
          height: parseInt(split[0]),
          widths: datas.map((d) => parseInt(d[0])),
          c: datas.map((d) => parseInt(d[1])),
          o: datas.map((d) => {
            return !d[2]
              ? { side: 'x', amount: 0 }
              : {
                  side: d[2][0],
                  amount: parseInt(d[2].substr(1)),
                };
          }),
        };
      });

      const cs = [
        rows[1].c[0],
        rows[1].c[1],
        rows[1].c[2],
        rows[0].c[0],
        rows[0].c[1],
      ];
      const os = [
        rows[1].o[0],
        rows[1].o[1],
        rows[1].o[2],
        rows[0].o[0],
        rows[0].o[1],
      ];
      const vals = {
        h: rows[0].height,
        v1: rows[1].widths[0],
        v2: rows[1].widths[0] + rows[0].widths[0],
        v3: rows[1].widths[0] + rows[0].widths[0] + rows[1].widths[1],
        v4:
          rows[1].widths[0] +
          rows[0].widths[0] +
          rows[1].widths[1] +
          rows[0].widths[1],
        c: cs.map((cc, i) => ({ block: i + 1, amount: cc || 0 })),
        o: os
          .map((oo, i) => ({
            block: i + 1,
            amount: oo ? oo.amount : 0,
            side: oo ? oo.side : 0,
          }))
          .filter((oo) => oo.amount > 0),
      };

      if (apply) _.extend(this.props, vals);
      return vals;
    },
    generateCode({ maxOverlaps = undefined } = {}) {
      let c = RandomGenerator.generateCode({ maxOverlaps });
      while (!this.testCode(c)) {
        c = RandomGenerator.generateCode({ maxOverlaps });
      }
      return c;
    },
    animateToCode(
      c,
      { duration = 1, ease = 'power2.inOut', onComplete = () => {} } = {}
    ) {
      const vals = this.loadFromCode(c, false);

      const data = {
        active: true,
      };

      gsap.killTweensOf(this.props);

      gsap.to(this.props, {
        duration,
        ease,
        ..._.pick(vals, 'h', 'v1', 'v2', 'v3', 'v4'),
        onComplete: () => {
          data.active = false;
          onComplete();
        },
      });

      this.props.c.forEach((c, i) => {
        gsap.killTweensOf(c);
        const to = vals.c.find((cc) => cc.block === c.block);
        gsap.to(c, {
          duration,
          ease,
          amount: to ? to.amount : 0,
        });
      });

      if (vals.o.length) {
        const match = this.props.o.find(
          (oo) => oo.block === vals.o[0].block && oo.side === vals.o[0].side
        );

        if (match) {
          gsap.killTweensOf(match);
          gsap.to(match, {
            amount: vals.o[0].amount,
            duration,
            ease,
          });
        } else {
          this.props.o.forEach((o, i) => {
            o.removing = true;
            gsap.killTweensOf(o);
            gsap.to(o, {
              amount: 0,
              duration,
              ease,
              onComplete: () => {
                this.props.o = _.without(this.props.o, o);
              },
            });
          });
          const newO = {
            block: vals.o[0].block,
            amount: 0,
            side: vals.o[0].side,
          };
          this.props.o = [...this.props.o, newO];
          gsap.killTweensOf(newO);
          gsap.to(newO, {
            amount: vals.o[0].amount,
            duration,
            ease,
          });
        }
      } else {
        this.props.o.forEach((o, i) => {
          gsap.killTweensOf(o);
          gsap.to(o, {
            amount: 0,
            duration,
            ease,
            onComplete: () => {
              this.props.o = _.without(this.props.o, o);
            },
          });
        });
      }
      return data;
    },
    createSVG({ adjust = 0.5 } = {}) {
      this.props.adjust = adjust;
      const svgG = this.blocks.layer.exportSVG({ asString: true });
      const el = document.createElement('div');
      el.innerHTML = `<svg viewBox="0 0 ${this.props.size.width} ${this.props.size.height}">${svgG}</svg>`;
      return el;
      this.props.adjust = 0.5;
    },
    testCode(code) {
      const vars = this.loadFromCode(code, false);
      const { h, v1, v2, v3, v4, c, o } = vars;
      const v0 = 0;
      const v5 = 20;
      const width = 20;
      const height = 20;

      const cs = c.map((c) => c.amount);

      const osr = _.range(5).map((i) => {
        const match = o.find((cc) => {
          if (!cc) return 0;
          return +cc.block === i + 1 && cc.side === 'R';
        });
        if (!match) return 0;
        return (match.amount / 20) * width;
      });

      const osl = _.range(5).map((i) => {
        const match = o.find((cc) => {
          if (!cc) return 0;
          return +cc.block === i + 1 && cc.side === 'L';
        });
        if (!match) return 0;
        return (match.amount / 20) * width;
      });

      const blocks = _.range(1, 6).map((block) => {
        let p1 = {};
        let p2 = {};
        switch (block) {
          case 1:
            p1.x = v0;
            p1.y = h + cs[0];
            p2.x = v1 + osr[0];
            p2.y = height;
            break;

          case 2:
            p1.x = v2 - osl[1];
            p1.y = h + cs[1];
            p2.x = v3 + osr[1];
            p2.y = height;
            break;

          case 3:
            p1.x = v4 - osl[2];
            p1.y = h + cs[2];
            p2.x = v5;
            p2.y = height;
            break;

          case 4:
            p1.x = v1 - osl[3];
            p1.y = 0;
            p2.x = v2 + osr[3];
            p2.y = h - cs[3];
            break;

          case 5:
            p1.x = v3 - osl[4];
            p1.y = 0;
            p2.x = v4 + osr[4];
            p2.y = h - cs[4];
            break;
        }
        return { p1, p2, width: p2.x - p1.x, height: p2.y - p1.y };
      });
      if (blocks.find((b) => b.height < 3)) return;
      const skinnyAmount = blocks.filter((b) => b.width < 3).length;
      if (skinnyAmount > 2) return;
      return true;
    },
  },
});
