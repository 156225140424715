import IMOG from '~/lib/imog';
import Paper from 'paper';
import _ from 'underscore';
import { map } from '~/lib/math';

export default IMOG.Component('Blocks', {
  options: {
    paper: null,
    addTo: null,
    mainColor: 'blue',
    smallColor: '#ccc',
  },

  props() {
    return {
      size: { width: 500, height: 500 },

      visible: true,
      gridVisible: true,

      logoData: {
        v1: 5,
        v2: 10,
        v3: 15,
        v4: 20,
        h: 10,
        c: [],
        o: [],
        adjust: 0.5,
      },
      computedData: (props) => ({ ...props.logoData, ...props.size }),
    };
  },

  setup({ options }) {
    this.paper = options.paper;

    this.layer = new this.paper.Layer();
    options.addTo.addChild(this.layer);

    this.b = _.range(5).map((index) => {
      const p = new this.paper.Path.Rectangle([0, 0], [0, 0]);
      p.fillColor = 'black';
      this.layer.addChild(p);
      return p;
    });

    this.smallLayer = new this.paper.Layer();
    this.layer.addChild(this.smallLayer);
    this.smallLayer.sendToBack();

    this.smallVertical = _.range(4 * 5 + 1).map((i) => {
      const p = new this.paper.Path();
      p.strokeColor = 'rgba(0,0,0,0.15)';
      p.add(new Paper.Point(0, 0));
      p.add(new Paper.Point(100, 0));
      this.smallLayer.addChild(p);
      return p;
    });

    this.smallHorizontal = _.range(4 * 5 + 1).map((i) => {
      const p = new this.paper.Path();
      p.strokeColor = 'rgba(0,0,0,0.15)';
      p.add(new Paper.Point(0, 0));
      p.add(new Paper.Point(100, 0));
      this.smallLayer.addChild(p);
      return p;
    });
  },

  hooks: {
    'set:visible'(v) {
      this.layer.visible = v;
    },

    'set:computedData'({ width, height, v1, v2, v3, v4, h, c, o, adjust }) {
      if (width === undefined || v1 === undefined) return;

      const v0 = 0;
      v1 = (v1 / 20) * width;
      v2 = (v2 / 20) * width;
      v3 = (v3 / 20) * width;
      v4 = (v4 / 20) * width;
      const v5 = width;

      h = (h / 20) * height;
      const hs = [0, h, height];

      const cs = _.range(5).map((i) => {
        const match = c.find((cc) => {
          if (!cc) return 0;
          return cc.block === i + 1;
        });
        if (!match) return 0;
        return (match.amount / 20) * height;
      });

      const osr = _.range(5).map((i) => {
        const match = o.find((cc) => {
          if (!cc) return 0;
          return +cc.block === i + 1 && cc.side === 'R';
        });
        if (!match) return 0;
        return (match.amount / 20) * width;
      });

      const osl = _.range(5).map((i) => {
        const match = o.find((cc) => {
          if (!cc) return 0;
          return +cc.block === i + 1 && cc.side === 'L';
        });
        if (!match) return 0;
        return (match.amount / 20) * width;
      });

      const b1 = this.b[0];
      b1.segments[0].point.x = -adjust + v0;
      b1.segments[0].point.y = -adjust + h;
      b1.segments[1].point.x = +adjust + v1 + osr[0];
      b1.segments[1].point.y = -adjust + h;
      b1.segments[2].point.x = +adjust + v1 + osr[0];
      b1.segments[2].point.y = +adjust + height - cs[0];
      b1.segments[3].point.x = -adjust + v0;
      b1.segments[3].point.y = +adjust + height - cs[0];

      const b2 = this.b[1];
      b2.segments[0].point.x = -adjust + v2 - osl[1];
      b2.segments[0].point.y = -adjust + h;
      b2.segments[1].point.x = +adjust + v3 + osr[1];
      b2.segments[1].point.y = -adjust + h;
      b2.segments[2].point.x = +adjust + v3 + osr[1];
      b2.segments[2].point.y = +adjust + height - cs[1];
      b2.segments[3].point.x = -adjust + v2 - osl[1];
      b2.segments[3].point.y = +adjust + height - cs[1];

      const b3 = this.b[2];
      b3.segments[0].point.x = -adjust + v4 - osl[2];
      b3.segments[0].point.y = -adjust + h;
      b3.segments[1].point.x = +adjust + v5;
      b3.segments[1].point.y = -adjust + h;
      b3.segments[2].point.x = +adjust + v5;
      b3.segments[2].point.y = +adjust + height - cs[2];
      b3.segments[3].point.x = -adjust + v4 - osl[2];
      b3.segments[3].point.y = +adjust + height - cs[2];

      const b4 = this.b[3];
      b4.segments[0].point.x = -adjust + v1 - osl[3];
      b4.segments[0].point.y = -adjust + cs[3];
      b4.segments[1].point.x = +adjust + v2 + osr[3];
      b4.segments[1].point.y = -adjust + cs[3];
      b4.segments[2].point.x = +adjust + v2 + osr[3];
      b4.segments[2].point.y = +adjust + h;
      b4.segments[3].point.x = -adjust + v1 - osl[3];
      b4.segments[3].point.y = +adjust + h;

      const b5 = this.b[4];
      b5.segments[0].point.x = -adjust + v3 - osl[4];
      b5.segments[0].point.y = -adjust + cs[4];
      b5.segments[1].point.x = +adjust + v4 + osr[4];
      b5.segments[1].point.y = -adjust + cs[4];
      b5.segments[2].point.x = +adjust + v4 + osr[4];
      b5.segments[2].point.y = +adjust + h;
      b5.segments[3].point.x = -adjust + v3 - osl[4];
      b5.segments[3].point.y = +adjust + h;
    },
    'set:gridVisible'(v) {
      this.smallLayer.visible = v;
    },
    'set:size'({ width, height }) {
      this.smallVertical.forEach((item, i) => {
        item.segments[0].point.x = map(i, 0, 4 * 5, 0, width);
        item.segments[1].point.x = map(i, 0, 4 * 5, 0, width);
        item.segments[0].point.y = 0;
        item.segments[1].point.y = height;
      });
      this.smallHorizontal.forEach((item, i) => {
        item.segments[0].point.x = 0;
        item.segments[1].point.x = width;
        item.segments[0].point.y = map(i, 0, 4 * 5, 0, height);
        item.segments[1].point.y = map(i, 0, 4 * 5, 0, height);
      });
    },
  },
});
