import _ from 'underscore';
import { clamp } from '~/lib/math';
import codeGenerator from './codeGenerator';

const randomVal = (min, max) => {
  return Math.min(min + Math.floor(Math.random() * (max + 1 - min)), max);
};

const toBase = (val, base = 21) => {
  return val.toString(base).toUpperCase();
};

const randomValBase = (min, max, base = 21) => {
  return toBase(randomVal(min, max), base);
};

const log = (log) => {
  return;
  console.log(`%c ● ${log} `, 'background: #ccc; color: #0000ff');
};

const generateVerticals = () => {
  const rand = randomVal(2, 3);
  const gaps = 20;
  let vs;
  let last;

  switch (rand) {
    case 2:
      // random fill
      log('RANDOM FILLING');
      vs = [];
      let remain = gaps;
      _.times(4, (i) => {
        const val = randomVal(1, remain - 4 + i);
        vs.push(val);
        remain -= val;
      });
      if (Math.random() > 0.5) {
        vs = vs.reverse();
      }
      last = 0;
      vs = vs.map((val) => {
        last += val;
        return last;
      });
      return {
        v1: vs[0],
        v2: vs[1],
        v3: vs[2],
        v4: vs[3],
      };
    case 3:
      // pairs
      log('PAIRS FILLING');
      const smallBlocks = randomVal(1, 3);
      const bigBlocks = randomVal(smallBlocks + 1, 4);
      const remainBlock = gaps - smallBlocks * 2 - bigBlocks * 2;
      vs = [smallBlocks, smallBlocks, bigBlocks, bigBlocks, remainBlock];
      if (Math.random() > 0.5) {
        vs = vs.reverse();
      }
      last = 0;
      vs = vs.map((val) => {
        last += val;
        return last;
      });
      return {
        v1: vs[0],
        v2: vs[1],
        v3: vs[2],
        v4: vs[3],
      };
  }
};

const generateCuts = ({ h }) => {
  let cs = [];

  ['top', 'bottom'].forEach((row) => {
    const amount = randomVal(0, 1);
    _.times(amount, (i) => {
      const block = _.sample(
        _.difference(
          row === 'top' ? [4, 5] : [1, 2, 3],
          cs.map((c) => c.block)
        )
      );
      const blockHeight = block < 4 ? 20 - h : h;
      const blockOptions = [
        clamp(Math.round(blockHeight * 0.5), 1, blockHeight - 1),
        clamp(Math.round(blockHeight * 0.75), 1, blockHeight - 1),
      ];

      let amount = _.sample(blockOptions);
      if (amount < 2) amount = 0;

      cs.push({ block, amount });
    });
  });

  cs = cs.filter((cc) => cc.amount > 0);
  return cs;
};

const generateOverlaps = ({ max = undefined, v1, v2, v3, v4 } = {}) => {
  const block_remap = { 1: 1, 2: 4, 3: 2, 4: 5, 5: 3 };
  const fullAllowed = '2L 4R'.split(' ');

  // we are working on left-to right block indexes, remapping later
  const points = [0, v1, v2, v3, v4, 20];
  const blocksWidths = _.range(1, 6).map(
    (index) => points[index] - points[index - 1]
  );
  blocksWidths.unshift(0);
  const amount =
    max === undefined ? (Math.random() > 0.5 ? 1 : 0) : randomVal(0, max);
  let os = [];
  _.times(amount, (i) => {
    const block = randomVal(1, 5);

    let side = _.sample(['L', 'R']);
    if (block === 1) side = 'R';
    if (block === 5) side = 'L';
    const gutterAmount =
      side === 'L' ? blocksWidths[block - 1] : blocksWidths[block + 1];
    let amount = 0;

    if (gutterAmount / 2 === Math.round(gutterAmount / 2)) {
      amount = gutterAmount / 2;
    }
    if (
      fullAllowed.find((code) => code === `${block}${side}`) &&
      Math.random() > 0.25
    ) {
      amount = gutterAmount;
    }
    if (amount < 4) amount = Math.random() > 0.95 ? gutterAmount : 0;
    log(amount);

    os.push({ block: block_remap[block], amount, side });
  });
  os = os.filter((cc) => cc.amount > 0);
  return os;
};

const generateCode = ({ maxOverlaps }) => {
  // console.log('NEW CODE');
  const h = { h: randomVal(1, 17) };
  const v = generateVerticals();
  const c = generateCuts({ h: h.h });
  const o = generateOverlaps({ ...v, max: maxOverlaps });

  return codeGenerator({ ...h, ...v, c, o });
};
export { generateCode };
